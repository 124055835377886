import {useState ,useEffect, useCallback} from 'react';
import axios  from 'axios';
import './App.css';
import useStyles from  './styles'
import Appbar from './components/appbar/Appbar';
import Home from './components/home/Home'
import MobileSlider from './components/mobilemenu/MobileSlider';
import Blog from './components/blog/Blog'
import {Route, useHistory} from "react-router-dom";import
 Posts from './components/posts/Posts'
import { ThemeProvider, createTheme} from '@mui/material/styles';
import { red } from '@mui/material/colors';
import InfoFooter from './components/footer/InfoFooter';
import { Box, Grid, Typography,useMediaQuery } from '@mui/material';
import ExxchangeRates from './components/exchangeRates/exxchangeRate/ExxchangeRate.jsx';
import {data,currencies} from './data.js';
import PageTemplate from './components/pagetemplate/PageTemplate.jsx';
import { Adsense } from '@ctrl/react-adsense';
import FirebaseNotifaction from './components/firbasenotification/FirebaseNotifaction.jsx';


 // Import the functions you need from the SDKs you need
 import { initializeApp } from "firebase/app";
 import { getAnalytics } from "firebase/analytics";
import Settings from './components/Settings/Settings.jsx';
 // TODO: Add SDKs for Firebase products that you want to use
 // https://firebase.google.com/docs/web/setup#available-libraries

function App() {
  


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/* const firebaseConfig = {
  apiKey: process.env.REACT_APP_FB_API_KEY,
  authDomain: process.env.REACT_APP_FB_DOMAIN,
  projectId: process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FB_STO_BUCKET,
  messagingSenderId: process.env.REACT_APP_FB_MESSAGE_ID,
  appId: process.env.REACT_APP_FB_APP_ID,
  measurementId: process.env.REACT_APP_FB_MESUREMENT_ID
};

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app); */
 
  const[pGCV,setPiGCV]=useState(1);
  const[areRatesLoaded,setAreRatesLoaded]=useState(false);
  const[rates,setRates]=useState([]);
  const [showMobileSidebar,setShowMobileSideBar]=useState(false);
  const [isdataBaseOpen,setIsdataBaseOpen]=useState(false)
  const [posts,setPosts]=useState([])
  const [searchresults,setSearchResults]=useState([])
  const classes =useStyles()
  const [page,setPage]=useState(1)
  const [pageSize]=useState(5)
  const history =useHistory()
  const BlogDataBase='Blog'
  const [dbVersion,setDbVersion]=useState(localStorage.getItem('Dbversion') ? localStorage.getItem('Dbversion'):1)
  // Define a media query for screens larger than 600px (typically desktop)
  const isDesktop = useMediaQuery('(min-width:600px)');

  /* const post_s =[{id:"0",title:'What is the value of pi ?',description:'Pi has no official vlaue yet , as pi value  will form through market commoodity',image:'./logo192.png'},{id:"1",title:'What is pi consensus value ?',description:'Even though pi has no official value yet , pi communitu members has a value to which some of the commuinuty sellers and buyers can agrred on  to make trasactions. 314159 is the GCV value many pioneers sing to when promoting pi . ',image:'./logo192.png'}] */
 
  const theme = createTheme({
    palette: {
      primary:{
     /*    main:"#faa532", */
        main:"#ff9800",
      },
        secondary: {
            main:red[500],
            contrastText:'#fff'
        },
        warning:{
          main:red[100],
          contrastText:'#fff'
        },
        info:{
          main:"#6d1b7b",
         /*  main:"#c75bda", */
         contrastText:'#fff'
        },
        paid:{
          main:"#2a7ade",
          contrastText:'#fff'
        },
        shipped:{
          main:"#d39012",
          contrastText:'#fff'
        },
        recieved:{
          main:"#8fd312",
          contrastText:'#fff'
        },
        completed:{
          main:"#34af4d",
          contrastText:'#fff'
        },
        textgrey:{
          main:"rgb(128,128,128)",
          contrastText:'#fff'
        },
        footerText:{
          main:"#fff"
        }
      },
});
    const resetFields = () =>{

      document.getElementById('pi').value=''
      document.getElementById('fiat').value=''
  
     }
    const handleMouseDown =()=>{
      setShowMobileSideBar(!showMobileSidebar);
    
   }

   async function handlegetRecords(){
    /* openDatabase(BlogDataBase, dbVersion)
    .then((db) => {
       db.getAllData(db).then((data)=>{
            return data
       })
    }) */
    }

   async function searchArticles(query) {
    console.log(query)
    try {
      const response = await fetch(`https://naeatestudio-cms.herokuapp.com/api/articles/search?query=${encodeURIComponent(query)}`);
      if (!response.ok) {
        throw new Error('Error occurred while searching');
      }
      const articles = await response.json();
      console.log(articles); // Returns the matching articles
      setSearchResults(articles.data)
      if (articles.data.length>0){

        history.push('/blog/search')
      }
    } catch (error) {
      console.error('Error fetching articles:', error);
    }
  }
  
  // Example usage
  //searchArticles('Pi Network');
  
   const handleShowMenu =() =>{
    console.log('..show menu')
    setShowMobileSideBar(!showMobileSidebar);
   }
    const goto =(url)=>{
       window.open(url, '_system');
    }
    const gotoPlayStore =()=>{
      const url='https://play.google.com/store/apps/details?id=com.naeatestudio.pifiatconverter&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
     /*  window.location.href='https://play.google.com/store/apps/details?id=com.naeatestudio.pifiatconverter&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1' */
      window.open(url, '_system');
    }
    const formarttoCurrency = (amount)=>{
     try{
      var value=0;
      var symbol=''
    if (amount!==undefined){
      if (amount>=1){
    
        value = symbol +amount.toFixed(3).replace(/\d(?=(\d{3})+\.)/g,"$&,");
      }else{
        value =symbol +amount.toFixed(8).replace(/\d(?=(\d{3})+\.)/g,"$&,");
       }
    }
      return value
     }catch(err){
      console.log(err)
    }      
  }

     const BlopPosts = ()=>{
      return (
        <Grid container >
               { posts && posts.length >0 ? <Blog posts={posts} page={page} setPage={setPage} handlegetArtitles={handlegetArtitles} /> :''}
        </Grid>
      )
     }

     const SearchResults = ()=>{
      return (
        <Grid container >
               { searchresults && searchresults.length >0 ? <Blog posts={searchresults} page={page} setPage={setPage} handlegetArtitles={handlegetArtitles} /> :''}
        </Grid>
      )
     }

     const Article = () =>{
      return(
        <Grid>
         {posts && posts.length >0 ?  <Posts posts={posts} />:''}
        </Grid>
      )
     }
     const openlink = ()=>{
       history.push(`/blog/article/Pi%20Network:%20Revolutionizing%20Digital%20Currency%20with%20Pi%20Converter`)
     }
     const AdSlotWrapper = () =>{
      return (
         <div>  <Adsense
                 client="ca-pub-3933719445212850"
                 slot="2852457680"
                style={{display:'block'}}
                 format="auto"
                 responsive='true'

                />
          </div>)
     }

     const ExchangeWrapperForBlog = () =>{
        return(
          <>
            {   rates ? <ExxchangeRates paramvalue={1} rates={rates} data={data} currencies={currencies} pGCV={pGCV} setPiGCV={setPiGCV} formarttoCurrency={formarttoCurrency} resetFields={resetFields}/>:''
              }
          </>
        )
     }
     const ExchangeWrapper = () =>{
      return (
        <Grid item   xs={12} sm={12} md={12} lg={12}   /* style={{backgroundColor:'antiquewhite'}} */>
        <Grid container className={classes.exchangeWrapper} direction={'column'}>
           {/* <Grid item xs={12}>
              <button onClick={()=>handlegetRecords()} >get Data</button> 
            </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12}  >
             <Adsense
              client="ca-pub-3933719445212850"
             slot="9077357511"
             style={{ width: "95vw", height: 90 }}
              format="
             /> 
             <Grid style={{width:'100%',border:'0px solid'}}>   
             <Grid item xs={{display:'none'}} sm={{display:'none'}}  md={{display:'none'}}  lg={{display:'block'}} >
                 <GoogleAd slot="2852457680" googleAdId="ca-pub-3933719445212850"/> 

                </Grid>         
             </Grid> 

             </Grid>*/}
             <Grid item xs={10} sm={12} md={12} lg={12}  style={{border:'0px solid orange',borderRadius:'2px',marginTop:1}}>
             {/* <Typography style={{padding:'2px'}} variant='body2' color={'indianred'} > Desktop and Laptop with core i5,8GB RAM or high needed at  GCV price Ugently, if you can provide or know anyone who can please contact email below</Typography> 
           <Typography variant="body2" > <a  href='mailto:naeatestudios@gmail. com'><EmailOutlined fontSize='small'className='topbarinfoIcon'/></a></Typography>*/}

              {   rates ? <ExxchangeRates paramvalue={1} rates={rates} data={data} currencies={currencies} pGCV={pGCV} setPiGCV={setPiGCV} formarttoCurrency={formarttoCurrency} resetFields={resetFields}/>:''
              }
             </Grid>

           { isDesktop? '' : <Grid item xs={12} sm={12} md={12} lg={12}>
            
             <Grid container alignItems={'center'} justifyContent='center'>
             <Grid item >
              {/*  <a href="googlechrome://play.google.com/store/apps/details?id=com.naeatestudio.pifiatconverter">link 1</a>
               <a href="googlechrome://example.com">try it on Chrome</a> */}
                <div style={{width:'100%',height:'auto',cursor:'pointer',border:'0px solid'}} onClick={()=>{gotoPlayStore()}}>
               <img alt='Get it on Google Play' width={120}  src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
              </div>
              </Grid>
           </Grid>
         </Grid>}

         
          {/* <Grid item  xs={12} sm={12} md={12} lg={{display:'none'}}>
   
            <AtmeBanner src={images[0]} variant='quiz'link='https://3ba1dhl0elfs2nddh32fizeyaa.hop.clickbank.net'/>
    

 
               <div style={{width:'80%',border:'0px solid'}}>  
               <GoogleAd slot="7846312808" 
               style={{display:'inline-block',width:'100%',height:'90px'}}
                 googleAdId="ca-pub-3933719445212850"/>
               </div>

              </Grid>*/}

             {isDesktop ?'': <Grid item xs={12} sm={12} md={12} lg={12}  justifyContent='space-between'>  
             <Typography variant='h5' fontWeight={600} color={'MenuText'}>{pGCV !==314159 ? 'Consensus Value' :'Pi Global Consensus Value'}</Typography>
              <Typography variant='h5'  fontWeight={600} color={'red'} >{`$${formarttoCurrency(pGCV)}`}</Typography>
              </Grid>}
              {isDesktop ?'' :<Grid item xs={12} sm={12} md={12} lg={12} padding={0.5} >
                <Grid container direction={'column'} justifyContent={'space-between'} padding={1}>
               
                  <Grid item >
                  <Typography style={{marinTop:'1vh'}} variant='body2' color={'GrayText'} >Pi is a new digital currency developed by Standford PhDs, with over 40 million members worldwide</Typography>
                  </Grid>  
                    <Grid item >
                    {/*     <Typography style={{marinTop:'2vh'}} variant='body2' color={'GrayText'} >The app uses static  currency rates for now  to make the conversion as request  from currencyapi.net its exhausted,will be fixed soon </Typography> */}
                    <br/>
              <Typography align='left' style={{marinTop:'0.5vh'}} variant='body1' color={'GrayText'} >Pi Converter is a revolutionary tool designed to address the challenges of Pi Coin transactions. It helps users convert Pi Coin to their local currencies accurately and efficiently.{/* Convert pi  currency to local currencies and convert local currency to pi  */}  </Typography>
                    </Grid>
                  <Grid item >
                   <Typography color='blue' onClick={()=>{openlink()}}>Read More</Typography>
                  </Grid>

              <br/>



                {/* <Typography style={{marinTop:'1vh'}} variant='body2' color={'GrayText'} > <a style={{textDecoration:'none'}} href='https://minepi.com/AbdulRazakNaeate'> click here to join if you are not a member</a> </Typography> 
                <AtmeBanner src={gamezimages[srcInt]} variant='gamez' link='http://play444.atmegame.com/'/>*/}

                 {/*    <Typography style={{marinTop:'2vh'}} variant='body2' color={'GrayText'} >The app uses real time currency rates from currencypi.net to make the conversion </Typography> */}
                </Grid>
              </Grid>}

               {
                isDesktop ? "" :
                <Grid item sm={{display:'block'}} xs={{display:'block'}} md={{display:'none'}}lg={{display:'none'}}   border={0} height={90} >
                  
                <Grid container justifyContent={'center'}>
                     <Grid item xs={12}>
                          <Adsense
                client="ca-pub-3933719445212850"
                slot="7791931357"
               style={{display:'inline-block',width: "100%", height: 90 }}
                format=""
                responsive='true'
               />
                
               
                     </Grid>
                  </Grid>    
               </Grid>
               }

                
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} marginTop={3}>
                     <Grid container  justifyContent='center'>
                    {
                      isDesktop?  <Grid item >
                      {/*  <a href="googlechrome://play.google.com/store/apps/details?id=com.naeatestudio.pifiatconverter">link 1</a>
                       <a href="googlechrome://example.com">try it on Chrome</a> */}
                        <div style={{width:'100%',height:'auto',cursor:'pointer',border:'0px solid'}} onClick={()=>{gotoPlayStore()}}>
                       <img alt='Get it on Google Play' width={120}  src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/>
                      </div>
                      </Grid>:''
                    }

                   </Grid>
                 </Grid>
        {/* <Grid item sm={{display:'block'}} xs={{display:'block'}} md={{display:'none'}}lg={{display:'block'}}   border={0} height={90} >
                 <Grid container justifyContent={'center'}>
                      <Grid item xs={12}>
                           <Adsense
                 client="ca-pub-3933719445212850"
                 slot="7791931357"
                style={{display:'inline-block',width: "100%", height: 90 }}
                 format=""
                 responsive='true'
                /> 
                 
                
                      </Grid>
                   </Grid>    
                </Grid>*/}
    </Grid>
      )
     } 

     const HomeWrapper =()=>{
      return(
       <Box>
         <Home rates={rates} posts={posts} pGCV={pGCV} setPiGCV={setPiGCV} gotoPlayStore={gotoPlayStore} formarttoCurrency={formarttoCurrency}/> 
         <BlopPosts/>
       </Box>
      )
     }


     const getArticles = useCallback(async()=>{
      
      const url=`https://naeatestudio-cms.herokuapp.com/api/articles?populate=*&pagination[page]=${page}&pagination[pageSize]=${pageSize}`
      ///api/articles?populate=*
     
      return await axios.get(url/* `${process.env.REACT_APP_STRAPI_HOST_API}${getpages}` */)
      //return await axios.get(`http://localhost:1337${getpages}`)
    },[page, pageSize])
          
         const handlegetArtitles = useCallback( async(isNavigate,load)=>{
          console.log(page)
          getArticles().then((response)=>{
              const data= response.data.data
                
                console.log(response.data.data)
              if (data.length>0){
                setPosts(response.data.data)
              
                if (isNavigate===true){
                   if (load === '+'){
                    setPage(page =>page + 1)
                   }else{
                    setPage(page =>page - 1)
                   }
                }
              }
              
            
          })
     },[getArticles,page] )

    useEffect(()=>{
      

        if (!isdataBaseOpen && posts.length){
          /* (async () => {
            setDbVersion(prev => prev+1)
            const db = await openDatabase(BlogDataBase, dbVersion);
            console.log(dbVersion)
                localStorage.setItem('Dbversion',dbVersion)
            try {
                await db.addDataArrayToStore(db, 'articles', posts);
                console.log('Data added successfully');
            } catch (error) {
                console.error('Error adding data:', error);
            }
        })(); */
        }
           var url='';
        if (!areRatesLoaded){
                 /* http://localhost:3005 */
           url =`/api/rates`;

           axios.get(url).then(response=>{

          console.log(response.data)
          setRates(response.data.rates);
          setPiGCV(response.data.pivalue ? response.data.pivalue :1)
          })
         
          handlegetArtitles(false)

        }
      return ()=>{
        setAreRatesLoaded(true);
      if (posts.length){
        setIsdataBaseOpen(true)
      }
      }  /**/
    },[areRatesLoaded, dbVersion, handlegetArtitles, isdataBaseOpen, posts, posts.length])
    
    
  return (
    <ThemeProvider theme={theme}>
    <div className="App" >
      <Route exact path={['/','/blog','/blog/article/:title',"/blog/search","/convert 1 pi to usd","/how much is 1 pi"]}>
      <Appbar handleShowMenu={handleShowMenu} searchArticles={searchArticles}/>
      { 
     showMobileSidebar ?
     <MobileSlider showMobileSidebar={showMobileSidebar} handleMouseDown={handleMouseDown}  setShowMobileSideBar={setShowMobileSideBar} pGCV={pGCV}setPiGCV={setPiGCV} resetFields={resetFields}/> :''
     }
    </Route>
     <Route  path ="/" exact>
     
      {/* <Home rates={rates} posts={posts} pGCV={pGCV} setPiGCV={setPiGCV} gotoPlayStore={gotoPlayStore} formarttoCurrency={formarttoCurrency}/> */}
       <PageTemplate LeftComponent={ExchangeWrapper} RightComponentOne={isDesktop ? HomeWrapper: BlopPosts} RightComponentTwo={AdSlotWrapper}/>
      </Route>
     
     <Route exact path="/convert 1 pi to usd">
     <PageTemplate LeftComponent={ExchangeWrapper} RightComponentOne={BlopPosts} RightComponentTwo={AdSlotWrapper} />

      </Route>

      <Route exact path="/how much is 1 pi">
      <PageTemplate LeftComponent={ExchangeWrapper} RightComponentOne={BlopPosts} RightComponentTwo={AdSlotWrapper}/>
      </Route> 
         <Route  path='/blog' exact>
            <PageTemplate LeftComponent={isDesktop ?ExchangeWrapper:AdSlotWrapper} RightComponentOne={BlopPosts} RightComponentTwo={AdSlotWrapper} />

      </Route>

      <Route  path='/blog/article/:title' exact>
      
      <PageTemplate LeftComponent= { isDesktop ? ExchangeWrapper:null  } RightComponentOne={Article} RightComponentTwo={AdSlotWrapper} />
      </Route>

      
      <Route  path='/blog/search' exact>
      
      <PageTemplate LeftComponent= { isDesktop ? ExchangeWrapper:null  } RightComponentOne={SearchResults} RightComponentTwo={AdSlotWrapper} />
      </Route>
      
    <Route path='/notification'>
        <FirebaseNotifaction/>
    </Route>

    <Route path='/settings314159'>
        <Settings/>
    </Route>

    <Route exact path={['/','/blog','/blog/search','/blog/article/:title']}>
     <InfoFooter goto={goto}/>
    </Route>
    </div>
</ThemeProvider>
  );
}

export default App;
