
import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import axios from 'axios';

const ConfigForm = ({onSuccess }) => {
    const [piPrice, setPiPrice] = useState('');
    const [configId]=useState('67b601abe5b5088efcdafbd5')
    useEffect(() => {
        if (configId) {
            axios.get(`/configs/${configId}`)
                .then((response) => setPiPrice(response.data.Pi_Price))
                .catch((error) => console.error('Error fetching config:', error));
        }
    }, [configId]);

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = { Pi_Price: piPrice };

        try {
            if (configId) {
                await axios.put(`/api/config/${configId}`, data);
            } else {/* http://localhost:3005 */
                await axios.post('/api/config', data);
            }
            onSuccess();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };

    return (
        <Box component="form" onSubmit={handleSubmit} sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 400, margin: 'auto', mt: 4 }}>
            <Typography variant="h6">{configId ? 'Update Config' : 'Create Config'}</Typography>
            <TextField
                label="Pi Price"
                variant="outlined"
                value={piPrice}
                type="number"
                onChange={(e) => setPiPrice(e.target.value)}
                required
            />
            <Button type="submit" variant="contained" color="primary">
                {configId ? 'Update' : 'Create'}
            </Button>
        </Box>
    );
};

export default ConfigForm;
