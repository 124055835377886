import React from 'react'
import ConfigForm from './ConfigForm'
import PageTemplate from '../pagetemplate/PageTemplate'

const Settings = () => {
  return (
    <div>
        <PageTemplate RightComponentOne={ConfigForm}/>
    </div>
  )
}

export default Settings
